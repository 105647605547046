import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import Grid from "@mui/material/Grid";
import moment from "moment";
import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchPatientResultData,
  fetchUserDetail,
} from "../../../../actions/index";
import ComplianceChart from "./ComplianceChart";
import DailyDizzynessScoreChart from "./DailyDizzynessScoreChart";


class PatientResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      complianceData: [],
      tile: "compliance",
      topic: "",
      showModal: false,
/*-------------------------START - TVH-349 Change the start-date to Today's date & end-date to days-6 (Staging)------------------*/

      // startDate: moment(Date.now()).subtract(7, "days").format("YYYY-MM-DD"),
      // endDate: moment(Date.now()).subtract(1, "days").format("YYYY-MM-DD"),

    //--------------------------- Uncomment the code for original changes & remove this code (UAT)--------------------// 
      startDate: moment(Date.now()).subtract(6, "days").format("YYYY-MM-DD"),
      endDate: moment(Date.now()).subtract(0, "days").format("YYYY-MM-DD"),      
/*-------------------------END - TVH-349 Change the start-date to Today's date & end-date to days-6 ------------------*/
      
      
      patientInfo: "",
      dates: {
        complianceCurrentDate: '',
        compliancePreviousDate: '',
        month: "",
        dizzynessCurrentDate: "",
        dizzynessPreviousDate: "",
        dizzynessMonth: "",
        selValue:""
      }
    };

    this.toggle = this.toggle.bind(this);
  }

  async componentWillMount() { }

  async componentDidMount() {
    let patientId = this.props.patientId;

/*-------------------------START - TVH-349 Change the start-date to Today's date & end-date to days-6 ( Staging )------------------*/

    // let currentDate = moment(Date.now()).subtract(1, 'days').format('YYYY-MM-DD');
    // let previousDate = moment(currentDate).subtract(6, 'days').format('YYYY-MM-DD');

      //--------------------------- Uncomment the code for original changes & remove this code ( UAT )--------------------// 
      let currentDate = moment(Date.now()).subtract(0, 'days').format('YYYY-MM-DD');
      let previousDate = moment(currentDate).subtract(6, 'days').format('YYYY-MM-DD');
/*-------------------------END - TVH-349 Change the start-date to Today's date & end-date to days-6 ------------------*/

let month = moment(currentDate).subtract(3, 'days').format('YYYY-MM-DD');

    this.setState({

      complianceCurrentDate: currentDate,
      compliancePreviousDate: previousDate,
      month: month,
      dizzynessCurrentDate: currentDate,
      dizzynessPreviousDate: previousDate,
      dizzynessMonth: month

    });


  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      this.props.patientInfo &&
      prevProps.patientInfo !== this.props.patientInfo
    ) {
      this.setState({ patientInfo: this.props.patientInfo });
      await this.props
        .fetchPatientResultData(
          "both",
          this.props.patientInfo.topic,
          "SCROLLWEB",
          this.props.patientInfo.id,
          this.state.startDate,
          this.state.endDate
        )
        .then((res) => {
          // this.setState({ complianceData: res.overviewModelData.timeListofComplianceFactors });
          // this.setState({ complianceData: data.overviewModelData.timeListofComplianceFactors})
        })
        .catch((error) => {
          console.log("Error fetching Patient Result Data - ", error);
        });
    }

    if (
      this.props.complianceChartData.length !== 0 &&
      this.props.complianceChartData !== prevProps.complianceChartData
    ) {
      this.setState({ complianceChartData: this.props.complianceChartData });
    }
  }

  async fetchComplianceData(startDate, endDate) {
    if (startDate && startDate != "" && endDate && endDate != "") {
      await this.props
        .fetchPatientResultData(
          "compliance",
          this.props.patientInfo.topic,
          "SCROLLWEB",
          this.props.patientId,
          startDate,
          endDate
        )
        .then((res) => { })
        .catch((error) => {
          console.log("Error fetching Patient Result Data - ", error);
        });
    }
  }

  async fetchDizzynessScoreData(startDate, endDate) {
    if (startDate && startDate != "" && endDate && endDate != "") {
      await this.props
        .fetchPatientResultData(
          "dizzyness",
          this.props.patientInfo.topic,
          "SCROLLWEB",
          this.props.patientId,
          startDate,
          endDate
        )
        .then((res) => { })
        .catch((error) => {
          console.log("Error fetching Patient Result Data - ", error);
        });
    }
  }

  toggle(m) {
    this.setState({ tile: m });
  }

  handleClose = () => {
    this.setState({
      showModal: false,
      complianceCurrentDate:this.state.complianceCurrentDate,
      compliancePreviousDate:this.state.compliancePreviousDate,
      month:this.state.month
    });
  };
  changeDates(curDate, prevDate,selValue) {
    let month = moment(curDate).subtract(3, 'days').format('MMMM YYYY');
    this.setState({
      ...this.state,
      complianceCurrentDate: curDate,
      compliancePreviousDate: prevDate,
      month: month,
      selValue:selValue
    });


    this.fetchComplianceData(prevDate, curDate);
  }
  changeDatesDizzyness(curDate, prevDate,dizzynessSelValue) {
    console.log("dizzynessSelValue::::",dizzynessSelValue);
    let month = moment(curDate).subtract(3, 'days').format('MMMM YYYY');
    this.setState({
      ...this.state,
      dizzynessCurrentDate: curDate,
      dizzynessPreviousDate: prevDate,
      dizzynessMonth: month,
      dizzynessSelValue:dizzynessSelValue
    });


    this.fetchDizzynessScoreData(prevDate, curDate);
  }
  render() {
    return (
      <>
        <Grid item xs={12} md={7} className="pl-40">
          <div className="patient-detail-holder cust-card h-100 p-4">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className="d-flex align-items-center">
                  <div className="font-figtree font-600 font-24 text-theme-gray-dark">
                    Results
                  </div>
                  <div className="mx-auto">
                   
                  </div>
                  {this.state.patientInfo.newPatient == false &&
                    <div
                      className={`${this.props.complianceChartData &&
                        this.props.dizzynessChartData &&
                        this.props.complianceChartData.length == 0 &&
                        this.props.dizzynessChartData.length == 0
                        ? "d-none"
                        : ""
                        } pointer w-80px text-right`}
                    >
                      <ZoomOutMapIcon
                        className="text-theme-gray-2"
                        onClick={() => {
                          this.setState({ showModal: true });
                        }}
                      />
                    </div>
                  }
                </div>

                {this.state.patientInfo.newPatient == false &&  !this.state.showModal &&(
                  <div className="name-edit-btn">


                    <div className={this.state.tile == "compliance" ? "d-block" : "d-none"}>

                      <ComplianceChart
                        data={this.state.complianceChartData ? this.state.complianceChartData : ''}
                        toggle={this.toggle}
                        showModal={this.state.showModal}
                        fetchComplianceData={this.fetchComplianceData.bind(
                          this
                        )}
                        changeDates={(curDate, prevDate,selValue) => { this.changeDates(curDate, prevDate,selValue) }}
                        complianceCurrentDate={this.state.complianceCurrentDate}
                        compliancePreviousDate={this.state.compliancePreviousDate}
                        month={this.state.month}
                        selValue={this.state.selValue}
                        patientInfo={this.props.patientInfo}
                      />

                    </div>
                    <div className={this.state.tile == "compliance" ? "d-none" : "d-block"}>
                      <DailyDizzynessScoreChart
                        data={this.props.dizzynessChartData}
                        toggle={this.toggle}
                        showModal={this.state.showModal}
                        fetchDizzynessScoreData={this.fetchDizzynessScoreData.bind(
                          this
                        )}
                        changeDatesDizzyness={(curDate, prevDate,dizzynessSelValue) => { this.changeDatesDizzyness(curDate, prevDate,dizzynessSelValue) }}
                        dizzynessCurrentDate={this.state.dizzynessCurrentDate}
                        dizzynessPreviousDate={this.state.dizzynessPreviousDate}
                        dizzynessMonth={this.state.dizzynessMonth}
                        dizzynessSelValue={this.state.dizzynessSelValue}
                        patientInfo={this.props.patientInfo}
                      />
                    </div>

                  </div>
                )}
                {this.state.patientInfo.newPatient == true && (
                  <>
                    <p>No results to show.</p>
                  </>
                )}
              </Grid>
            </Grid>
          </div>
        </Grid>
        <div className="">
          <Modal show={this.state.showModal} onHide={this.handleClose} dialogClassName="results-modal" size="lg">            
            <Modal.Body>
              <div className="d-flex align-items-center justify-content-between mb-3">
                <span className="font-600 font-24 text-theme-gray-dark ml-3">Results</span>
                <CloseFullscreenIcon className="d-flex align-items-right pointer" onClick={() => this.handleClose()}/>
              </div>
              <ComplianceChart data={this.state.complianceChartData} fetchComplianceData={this.fetchComplianceData.bind(this)}
                  changeDates={(curDate, prevDate,selValue) => { this.changeDates(curDate, prevDate,selValue) }}
                  complianceCurrentDate={this.state.complianceCurrentDate}
                  compliancePreviousDate={this.state.compliancePreviousDate}
                  month={this.state.month} popupModal={true} selValue={this.state.selValue}
                  patientInfo={this.props.patientInfo}/>
              <DailyDizzynessScoreChart data={this.props.dizzynessChartData}
                  fetchDizzynessScoreData={this.fetchDizzynessScoreData.bind(
                    this
                  )}
                  changeDatesDizzyness={(curDate, prevDate,dizzynessSelValue) => { this.changeDatesDizzyness(curDate, prevDate,dizzynessSelValue) }}
                  dizzynessCurrentDate={this.state.dizzynessCurrentDate} dizzynessPreviousDate={this.state.dizzynessPreviousDate}
                  dizzynessMonth={this.state.dizzynessMonth} dizzynessSelValue={this.state.dizzynessSelValue}
                  patientInfo={this.props.patientInfo}/>
            </Modal.Body>
          </Modal>
          
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    patientInfo: state.security.user.role=='TVH SUPER ADMIN'?state.superAdminPatientData.superAdminPatients:state.userTableData,
    complianceChartData: state.patientResultsCharts.compliance,
    dizzynessChartData: state.patientResultsCharts.dizzyness,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchUserDetail,
      fetchPatientResultData,
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(PatientResult);
