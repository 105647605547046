import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import BarChart from './BarChart';
import ResultChartCalendarDizzyness from './ResultChartCalendarDizzyness';
import ChartDataLabels from "chartjs-plugin-datalabels";
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import moment from "moment";

const options = {
    animation: false,
    plugins: {
        tooltip: {
            callbacks: {
                title: function (xDatapoint) {
                    return xDatapoint[0].label;
                },
                label: function (yDatapoint) {
                    if (yDatapoint.raw == '10.00001') {
                        // return '0';
                        return;
                    } else {
                        // return yDatapoint.raw;
                        return;
                    }
                }
            }
        },
        // title: {
        //     display: true,
        //     text: 'MAXIMUM DAILY DIZZYNESS SCORE (0-9)'
        // },
        datalabels: {
            display: true,
            color: "white",
            // formatter: Math.round,
            anchor: "center",
            offset: 0,
            align: "top",
            font: {
                size: 18,
                weight: 'bold',
                family: 'Figtree',
            },
            formatter: function (value, context) {
                if (value == 10.00001) {
                    return '?';
                }
                if (value == 0.10){
                    return '';
                }

            }
        },
        legend: {
            boxWidth: 0,
            display: false
        },
    },

    scales: {
        x: {
            grid: {
                display: false,
                // drawOnChartArea:false,
            },
            display: true,
            // title: {
            //     display: true,
            //     text: 'Day',
            //     align: 'end',
            //     font: {
            //         size: 14,
            //         weight: 'normal'
            //     },
            //     color: '#000',
            // },
            tick: {
                display: true
            },
            ticks: {
                font: {
                    family: 'Figtree', // Your font family                   
                },
            },
        },
        y: {
            grid: {
                // display: false,
                drawOnChartArea: false,
            },
            max: 10,
            display: true,
            // title: {
            //     display: true,
            //     text: 'Score',
            //     align:'end',
            //     font:{
            //         size:14,
            //         weight:'bold'
            //     }
            //     //   color: '#911',
            // },

            // tick: {
            //     display: true
            // },
            ticks: {
                font: {
                    family: 'Figtree', // Your font family

                },
            },
        }
    },

}
export class DailyDizzynessScoreChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},

            currentDate: '',
            dizzynessPreviousDate: '',
            dizzynessMonth: '',
            dizzynessSelValue: '',
        }
    }
    componentDidMount() {
        // console.log('Dizzyness Resport ==> ',this.props.complianceChartData)
        this.updateDizzynessScoreData();
        this.setState({

            currentDate: this.props.dizzynessCurrentDate,
            dizzynessPreviousDate: this.props.dizzynessPreviousDate,
            dizzynessMonth: this.props.dizzynessMonth,
            dizzynessSelValue: this.props.dizzynessSelValue,
        });
    }

    updateDizzynessScoreData() {
        this.setState({
            data: {
                labels: this.props.data.map(date => {
                    return date.stringTime.toUpperCase()
                }),
                datasets: [{
                    data: this.props.data.map(date => {                       
                        if (date.vvasScore == 0) {
                            if((moment.tz(date.id.time,date.timezone).format('yyyy-MM-DD') < moment(this.props.patientInfo.stringCreateAt).format('yyyy-MM-DD'))?true:( !date.hasVORx1YawGame && !date.hasVORx1PitchGame && !date.hasBalanceWeightShiftGame && !date.hasSingleLegBalanceGame )?true:false){
                                return '0';
                            }else{
                                return '10.00001';
                            }
                        } else {
                            return date.vvasScore
                        }
                    }),
                    label: "",
                    fill: false,
                    //borderSkipped: false,
                    backgroundColor: this.props.data.map(date => { return date.vvasScore == 0 ? '#fff' : date.vvasScore == 0.10? 'red' : date.vvasScore < 4 ? '#099A80' : date.vvasScore < 7 ? '#D18819' : '#EB1E4E' }),
                    borderColor: this.props.data.map(date => { return date.vvasScore == 0 ? 'red' : '' }),
                    borderWidth: this.props.data.map(date => { return date.vvasScore == 0 ? '3' : '' }),
                    datalabels: {
                        labels: {
                            value: {
                                color: this.props.data.map(date => { return date.vvasScore == 0 ? 'red' : "#fff" })
                            }
                        },
                        display: function (context) {
                            if (context.dataset.data[context.dataIndex] < 2) {
                                return false; // display labels with an odd index
                            } else {
                                return true; // display labels with an odd index
                            }
                        }
                    },


                }]
            }
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.data !== prevProps.data) {
            this.updateDizzynessScoreData();
        }
        if (this.state.dates && this.state.dates !== prevState.dates) {
            // console.log('Dizzyness Chart Dates ===> ',this.state.dates)
            //this.props.fetchDizzynessScoreData(this.state.dates.previousDate, this.state.dates.currentDate);
        }
        if (this.props.dizzynessCurrentDate && this.props.dizzynessCurrentDate != prevProps.dizzynessCurrentDate) {
            let currentDate = this.props.dizzynessCurrentDate;

            this.setState({
                ...this.state,
                currentDate: currentDate,
            });

        }

        if (this.props.dizzynessPreviousDate && this.props.dizzynessPreviousDate != prevProps.dizzynessPreviousDate) {
            let previousDate = this.props.dizzynessPreviousDate;
            this.setState({
                ...this.state,

                dizzynessPreviousDate: previousDate

            });

        }
        if (this.props.dizzynessMonth && this.props.dizzynessMonth != prevProps.dizzynessMonth) {
            this.setState({
                ...this.state,
                dizzynessMonth: this.props.dizzynessMonth,
            });
        }
        if (this.props.dizzynessSelValue && this.props.dizzynessSelValue != prevProps.dizzynessSelValue) {
            this.setState({
                ...this.state,
                dizzynessSelValue: this.props.dizzynessSelValue,
            });
        }
    }

    changeDatesDizzyness(curDate, prevDate, dizzynessSelValue) {
        console.log("dizzynessSelValue::::", dizzynessSelValue);
        // this.setState({
        //     dates: {
        //         currentDate: curDate,
        //         previousDate: prevDate
        //     }
        // });
        //this.props.fetchDizzynessScoreData(prevDate, curDate);
        this.props.changeDatesDizzyness(curDate, prevDate, dizzynessSelValue)
    }

    toggle(m) {
        this.props.toggle(m);
    }

    render() {
        return (
            <div>
                <div className="mx-auto mb-3">
                    <div className="d-flex d-flex w-100 align-items-center">
                        <div className="mx-auto font-figtree font-600 font-14">MAXIMUM DAILY DIZZINESS SCORE (0-9)</div>
                    </div>
                </div>
                <div className='d-flex align-items-center'>
                    <div className='text-center d-flex flex-column align-items-center px-2'>
                        {this.props.showModal == false &&
                            <>
                                <ArrowBackIosNewOutlinedIcon onClick={() => { this.toggle('compliance') }} className="rounded-circle bg-theme-secondary-blue text-white p-2 pointer square-35" />
                                <span className='font-figtree font-500 font-14 text-primary-theme mt-2'>Compliance</span>
                            </>
                        }
                    </div>
                    <div className='w-100 d-flex'>
                        <div className='w-100'>
                            <div className='w-100 d-flex align-items-center'>
                                <span className='font-figtree font-400 font-14'>Score</span>
                                <div className='text-center w-100'>
                                    <ResultChartCalendarDizzyness changeDatesDizzyness={this.changeDatesDizzyness.bind(this)} dizzynessCurrentDate={this.state.currentDate} dizzynessPreviousDate={this.state.dizzynessPreviousDate} dizzynessMonth={this.state.dizzynessMonth} dizzynessSelValue={this.state.dizzynessSelValue} />
                                </div>
                            </div>
                            <BarChart title='' data={this.state.data} options={options}></BarChart>
                        </div>
                        <span className='font-figtree font-400 font-14 align-self-end -top-2 position-relative'>Day</span>
                    </div>
                    <div className='px-2'></div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        patientInfo: state.security.user.role=='TVH SUPER ADMIN'?state.superAdminPatientData.superAdminPatients:state.userTableData,
        complianceChartData: state.patientResultsCharts.compliance,
        dizzynessChartData: state.patientResultsCharts.dizzyness
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            // fetchUserDetail,
            // fetchPatientResultData
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(DailyDizzynessScoreChart)