import React, { Component } from "react";
import { connect } from "react-redux";
import {
  setExportDateRange,
  getExportData,
  fetchUserTableData,
  fetchAllProviders,
  fetchSelectUser,
  fetchUserDetail,
  fetchPatientNotifications,
  fetchSuperAdminPatientsByPatientId,
} from "../../../actions/index";
import { bindActionCreators } from "redux";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";

import PatientInfo from "./patientDetail/patientInfo";
import PatientResult from "./patientDetail/patientResult";
import PatientProgram from "./patientDetail/patientProgram";
import NotificationManager from "./NotificationManager";
import NotificationStatusBlock from "./patientDetail/NotificationStatusBlock";
import { Link } from "react-router-dom";
import * as routes from "../../../constants/routes";
import moment from "moment";
import DateRangeSelector from "./DateRangeSelector";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import ExportModal from "./exportModal";
import Modal from "react-bootstrap/Modal";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import CloseIcon from '@mui/icons-material/Close';
import jsPDF from 'jspdf';
import html2pdf from 'html2pdf.js';
import PDFPatientInfo from './pdfPatientInfo';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
// import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

class PatientDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      patientInfo: {
        fullName: "",
        firstName: "",
        lastName: "",
        pin: "",
        kitNumber: "",
        medicalRecordNumber: "",
        phoneNumber: "",
        email: "",
        ptName: "",
        username: "",
        newPatient: 'false',
        locationName: "",
        providerName: ""
      },
      disableDataExport: true,
      showExportModal: false,
      exportData: []
    };

    this.handleClose = this.handleClose.bind(this)
  }

  componentDidMount() {
    let queryParams = this.props.match.params;
    let patientId = queryParams.id;
    let currentUser = this.props.currentUser
    if (currentUser == '' || Object.keys(currentUser).length == 0) {
      window.location.href = '/';
      return false;
    }
    else {
      let currentUserRole = currentUser.role;
      if (currentUserRole.toLowerCase() != 'provider' && currentUserRole.toLowerCase() != 'tvh super admin') {
        this.props.history.push(routes.USER);
        return false;
      }
    }
    //console.log("patientId::::",queryParams.id);
    //this.props.fetchUserDetail(queryParams.id);
    this.setState({ patientId: patientId });
    //this.props.fetchUserDetail(patientId);
    this.getPatientDetail(patientId);
    this.props.fetchPatientNotifications(patientId);
  }
  getPatientDetail(patientId) {
    if(this.props.currentUser.role == 'TVH SUPER ADMIN'){
      console.log('SuperAdmin Patient Info');
      this.props.fetchSuperAdminPatientsByPatientId(patientId);
    }else{
      console.log('Patient Info');
      this.props.fetchUserDetail(patientId);
    }
  }
  fetchUserInfo = (id) => {
    this.getPatientDetail(id);
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.patientInfo &&
      prevProps.patientInfo !== this.props.patientInfo
    ) {
      // console.log('PatientInfo Data==>',this.props.patientInfo)
      let patientDetail = {
        fullName: this.props.patientInfo.fullName,
        firstName: this.props.patientInfo.firstName,
        lastName: this.props.patientInfo.lastName,
        pin: this.props.patientInfo.pin,
        kitNumber: this.props.patientInfo.kitNumber,
        medicalRecordNumber: this.props.patientInfo.medicalRecordNumber,
        phoneNumber: this.props.patientInfo.phoneNumber,
        email: this.props.patientInfo.email,
        ptName: this.props.patientInfo.ptName,
        username: this.props.patientInfo.username,
        //newPatient:true,
        newPatient: this.props.patientInfo.newPatient,
        clinicName: this.props.patientInfo.locationName,

      };
      this.setState({ patientInfo: patientDetail, patientUsername: this.props.patientInfo.username });
    }

    if (this.props.patientInfo && prevProps.patientInfo !== this.props.patientInfo) {
      let payload = {
        email: this.props.patientInfo.email ? this.props.patientInfo.email : '',
        topic: this.props.patientInfo.topic !== 'not defined' ? this.props.patientInfo.topic : 'ASTHMA',
        listType: '*WEB'
      }
      this.props.fetchSelectUser(payload);
    }

    if (this.props.exportDateRange !== prevProps.exportDateRange && this.props.exportDateRange.startDate && this.props.exportDateRange.endDate) {
      this.setState({
        disableDataExport: false
      })
    }

    if (this.props.exportData && this.props.exportData !== prevProps.exportData) {
      this.setState({
        exportData: this.props.exportData
      })
    }

  }

  handleClose() {
    this.setState({ showExportModal: false });
    // this.props.clearExportData();
  }


  async exportData() {
    const dates = this.props.exportDateRange;
    const startDate = moment(this.props.exportDateRange.startDate).format('YYYY-MM-DD');
    const endDate = moment(this.props.exportDateRange.endDate).format('YYYY-MM-DD');
    await this.props.getExportData(
      "both",
      this.props.patientInfo.topic,
      "SCROLLWEB",
      this.props.patientInfo.id,
      startDate,
      endDate
    );

    this.setState({ showExportModal: true })
  }

  getYesterdayDate() {
    const today = new Date();
    const yesterday = new Date(today.setDate(today.getDate() - 1))
    return yesterday;
  }


  exportToPDF() {
    var element = document.getElementById('pdfcontent');
    var opt = {
      margin: 8,
      filename: `${this.props.patientInfo.firstName + '_' + this.props.patientInfo.lastName + '_' + moment(this.props.exportDateRange.startDate).format('MM/DD/YYYY') + '-' + moment(this.props.exportDateRange.endDate).format('MM/DD/YYYY')}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 1.5 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
    };

    html2pdf().from(element).set(opt).save();
  }

  render() {
    return (
      <>
        <Grid container className="MuiToolbar-gutters pd-top-6 bg-dark-theme min-h-100">
          <Box sx={{ flexGrow: 1, }} className="patient-details push-mt-30">
            <div className="cust-breadcrumb d-flex align-items-center mb-3">
              <p>
                {this.props.currentUser.role=="TVH SUPER ADMIN"?<Link to={routes.SUPERADMINPATIENTS}>Patients</Link>:<Link to={routes.PATIENT}>Dashboard</Link>}
              </p>
              <p>
                <ArrowForwardIosRoundedIcon />
              </p>
              <p className="link">{this.state.patientInfo.fullName}</p>
            </div>
            <Grid container spacing={2}>
              {this.state.patientId && (
                <>
                  {
                    <div><NotificationStatusBlock /></div>}
                  <div className='d-flex justify-content-end align-items-center w-100'>
                  <span className="font-figtree font-500 font-14">Select date range for patient report: </span>
                    <DateRangeSelector setDateRange={this.props.setExportDateRange} maxDate={this.getYesterdayDate()} />
                    <button className="bg-primary-theme text-white pointer rounded-20 theme-btn border-0 font-figtree font-700 font-14 px-4 export-button"
                      onClick={() => { this.exportData() }} disabled={this.state.disableDataExport}>
                      <SaveAltIcon /> Export
                    </button>
                  </div>
                  <PatientInfo
                    patientId={this.state.patientId}
                    patientInfo={this.state.patientInfo}
                    fetchUserinfo={(id) => this.fetchUserInfo(id)}
                  />
                  <PatientResult patientId={this.state.patientId} patientInfo={this.state.patientInfo} />
                  {this.state.patientUsername && this.state.patientUsername != '' &&
                    <PatientProgram patientId={this.state.patientId} patientUsername={this.state.patientUsername} patientInfo={this.state.patientInfo} fetchUserinfo={(id) => this.fetchUserInfo(id)} />
                  }
                </>
              )}
            </Grid>
          </Box>
        </Grid>
        <Modal show={this.state.showExportModal} onHide={this.handleClose} dialogClassName="results-modal" size="lg">
          <Modal.Body>
            <div className="d-flex align-items-center justify-content-between mb-3">
              <span className="font-600 font-24 text-theme-gray-dark ml-3">Results</span>
              <CloseIcon className="d-flex align-items-right pointer" onClick={() => this.handleClose()} />
            </div>
            <button className="bg-primary-theme text-white pointer rounded-20 theme-btn border-0 font-figtree font-700 font-16 px-4 py-3" onClick={() => { this.exportToPDF() }}><SaveAltIcon />  Export</button>
            <div id='pdfcontent' className="w-100 pt-2">
              <PDFPatientInfo patientInfo={this.props.patientInfo} DateRange={this.props.exportDateRange} />
              <div>
                {this.props.exportData &&
                  this.props.exportData.map((chunk, index) => {
                    return <div>
                      <ExportModal data={chunk} patientInfo={this.props.patientInfo} pageNumber={index + 1} totalPages={this.props.exportData.length}></ExportModal>
                      &nbsp;
                    </div>
                  })
                }
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    // patientInfo: state.userTableData,
    patientInfo: state.security.user.role=='TVH SUPER ADMIN'?state.superAdminPatientData.superAdminPatients:state.userTableData,
    showNotificationBlock: state.notifications.showNotificationBlock,
    exportDateRange: state.patientResultsCharts.exportDateRange,
    exportData: state.patientResultsCharts.exportData,
    currentUser: state.security.user,
    complianceChartData: state.patientResultsCharts.compliance,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setExportDateRange,
      getExportData ,
      fetchUserTableData,
      fetchAllProviders,
      fetchSelectUser,
      fetchUserDetail,
      fetchPatientNotifications,
      fetchSuperAdminPatientsByPatientId,
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(PatientDetail);
