import React, { Component } from 'react'
import BarChart from './BarChart';
import ResultChartCalendarCompliance from './ResultChartCalendarCompliance';
import ChartDataLabels from "chartjs-plugin-datalabels";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import moment from "moment";

const options = {
    animation: false,
    plugins: {
        tooltip: {
            callbacks: {
                title: function (xDatapoint) {
                    var detailsData = xDatapoint[0].dataset.otherDetails.filter((details) => details.label == xDatapoint[0].label)[0];
                    return `${xDatapoint[0].label} \n${detailsData.hasVORx1YawGame?'\nVORx1 Yaw = ' + detailsData.vorx1_yaw + '%':''}  ${detailsData.hasVORx1PitchGame?"\nVORx1 Pitch = " +  detailsData.vorx1_pitch + '%':''} ${detailsData.hasBalanceWeightShiftGame?'\nBalance Weight Shift = ' + detailsData.balance_weight_shift +'%':'' } ${detailsData.hasSingleLegBalanceGame?'\nBalance Sitting/Standing = ' + detailsData.balance_single_leg + '%' :''}`;
                },
                label: function (yDatapoint) {
                    if (yDatapoint.raw == '100.00001') {
                        // return '0';
                    } else {
                        // return yDatapoint.raw;
                    }
                },

            },
            titleFont: {
                family: 'Figtree'
            }
        },
        // title: {
        //     display: true,
        //     text: 'DAILY COMPLIANCE'
        // },
        datalabels: {
            display: true,
            color: "white",

            // formatter: Math.round,
            anchor: "center",
            offset: 0,
            align: "top",
            font: {
                size: 18,
                weight: 'bold',
                family: 'Figtree',
            },
            formatter: function (value, context) {
                if (value == 100.00001) {
                    return '?';
                }

            }
        },
        legend: {
            boxWidth: 0,
            display: false
        },
    },

    scales: {
        x: {
            grid: {
                display: false
            },
            ticks: {
                font: {
                    family: 'Figtree', // Your font family
                },
            },
            // title: {
            //     display: true,
            //     text: 'Day',
            //     align: 'end',
            //     font: {
            //         size: 14
            //     },
            //     color: '#000',
            // },
        },
        y: {
            max: 100,
            color: '#000',
            grid: {
                // display: false
                drawOnChartArea: false
            },
            // title: {
            //     display: true,
            //     text: 'Compliance(%)',
            //     align:'end',
            // font:{
            //     weight:'bold',
            //     size:14
            // },
            //     maxRotation:90,
            //       color: '#911',
            // },
            layout: {
                padding: {
                    bottom: 20
                }
            },
            ticks: {
                font: {
                    family: 'Figtree', // Your font family
                },
            },
        }
    }
}
export class ComplianceChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},

            complianceCurrentDate: '',
            compliancePreviousDate: '',
            month: '',
            selValue: '',

        }
    }
    componentDidMount() {
        if (this.props.data) {
            this.updateComplianceData();
        }
        this.setState({

            complianceCurrentDate: this.props.complianceCurrentDate,
            compliancePreviousDate: this.props.compliancePreviousDate,
            month: this.props.month,
            selValue: this.props.selValue,

        });

    }

    updateComplianceData() {
        this.setState({
            data: {
                labels: this.props.data.map(date => date.stringTime.toUpperCase()),
                // title: this.props.data.map(date => date.stringTime.toLowerCase()),
                datasets: [{
                    data: this.props.data.map(date => {
                        if (date.compliance == 0) {
                            if ((moment.tz(date.id.time,date.timezone).format('yyyy-MM-DD') < moment(this.props.patientInfo.stringCreateAt).format('yyyy-MM-DD'))?true:( !date.hasVORx1YawGame && !date.hasVORx1PitchGame && !date.hasBalanceWeightShiftGame && !date.hasSingleLegBalanceGame )?true:false) {
                                return '0';
                            }else{
                                return '100.00001';
                            }
                        } else {
                            return Math.round(date.compliance)
                        }
                    }),
                    label: '',
                    backgroundColor: this.props.data.map(date => { return Math.round(date.compliance) == 0 ? '#fff' : Math.round(date.compliance) > 65 ? '#099A80' : Math.round(date.compliance) > 50 ? '#D18819' : '#EB1E4E' }),
                    fill: false,
                    borderColor: this.props.data.map(date => { console.log(date.compliance); return Math.round(date.compliance) == 0 ? 'red' : '' }),
                    borderWidth: this.props.data.map(date => { return Math.round(date.compliance) == 0 ? '3' : '' }),
                    datalabels: {
                        labels: {
                            value: {
                                color: this.props.data.map(date => { return Math.round(date.compliance) == 0 ? 'red' : "#fff" })
                            }
                        },
                        display: function (context) {
                            if (Math.round(context.dataset.data[context.dataIndex]) < 20) {
                                return false; // display labels with an odd index
                            } else {
                                return true; // display labels with an odd index
                            }
                        }
                    },

                    otherDetails: this.props.data.map(date => {
                        return {
                            vorx1_yaw: Math.round(date.compliance_vorx1_yaw),
                            vorx1_pitch: Math.round(date.compliance_vorx1_pitch),
                            balance_weight_shift: Math.round(date.compliance_balance_weight_shift),
                            balance_single_leg: Math.round(date.compliance_balance_single_leg),
                            label: date.stringTime.toUpperCase(),
                            hasVORx1YawGame: date.hasVORx1YawGame,
                            hasVORx1PitchGame: date.hasVORx1PitchGame,
                            hasBalanceWeightShiftGame: date.hasBalanceWeightShiftGame,
                            hasSingleLegBalanceGame: date.hasSingleLegBalanceGame,
                            hasTwoTarget: date.hasTwoTarget,
                            hasImaginaryTargets:date.hasImaginaryTargets,
                        }
                    }),
                    // compliance_vorx1_yaw
                    // compliance_vorx1_pitch
                    // compliance_balance_weight_shift
                    // compliance_balance_single_leg
                }]
            }
        })
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.props.data !== prevProps.data) {
            this.updateComplianceData();
        }

        if (this.props.complianceCurrentDate && this.props.complianceCurrentDate != prevProps.complianceCurrentDate) {
            let currentDate = this.props.complianceCurrentDate;
            this.setState({
                ...this.state,
                complianceCurrentDate: currentDate,
            });

        }

        if (this.props.compliancePreviousDate && this.props.compliancePreviousDate != prevProps.compliancePreviousDate) {
            let previousDate = this.props.compliancePreviousDate;
            this.setState({
                ...this.state.dates,

                compliancePreviousDate: previousDate

            });

        }
        if (this.props.month && this.props.month != prevProps.month) {
            this.setState({
                ...this.state,
                month: this.props.month,
            });
        }

        if (this.props.selValue && this.props.selValue != prevProps.selValue) {
            this.setState({
                ...this.state,
                selValue: this.props.selValue,
            });
        }


    }

    changeDates(curDate, prevDate, selValue) {
        // this.setState({
        //     dates: {
        //         complianceCurrentDate: curDate,
        //         compliancePreviousDate: prevDate
        //     }
        // });
        // console.log("curDate:::",curDate);
        // this.props.fetchComplianceData(prevDate, curDate);
        this.props.changeDates(curDate, prevDate, selValue);
        //console.log("selValue:::",selValue);
        //this.setState({selValue:selValue});
        //this.setState({month:selValue});
    }

    toggle(m) {
        this.props.toggle('dizzyness-score');
    }
    render() {

        return (
            <div>
                <div className="mx-auto my-2">
                    <div className="d-flex d-flex w-100 align-items-center">
                        <div className="mx-auto font-figtree font-600 font-14 pl-2">DAILY COMPLIANCE</div>
                    </div>
                </div>
                <div className='d-flex align-items-center mb-4'>
                    <div className='px-2'></div>
                    <div className='w-100 d-flex'>
                        <div className='w-100'>
                            <div className='w-100 d-flex align-items-center'>
                                <span className='font-figtree font-400 font-14 text-nowrap'>Compliance (%)</span>
                                <div className='text-center w-100'>
                                    <ResultChartCalendarCompliance changeDates={this.changeDates.bind(this)} complianceCurrentDate={this.state.complianceCurrentDate} compliancePreviousDate={this.state.compliancePreviousDate} month={this.state.month} selValue={this.state.selValue} />
                                </div>
                            </div>
                            {/* {console.log('Chart Data===>',this.state.data)} */}
                            <BarChart title='' data={this.state.data} options={options} ></BarChart>
                        </div>
                        <span className='font-figtree font-400 font-14 align-self-end -top-2 position-relative'>Day</span>
                    </div>
                    <div className='text-center d-flex flex-column align-items-center px-2'>
                        {this.props.showModal == false &&
                            <>
                                <ArrowForwardIosIcon onClick={() => { this.toggle('dizzyness') }} className="rounded-circle bg-theme-secondary-blue text-white p-2 pointer square-35" />
                                <span className='font-figtree font-500 font-14 text-primary-theme mt-2'>Dizziness</span>
                            </>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default ComplianceChart