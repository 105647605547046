import Pagination from "@material-ui/lab/Pagination";
import Box from "@mui/material/Box";
import React, { Component } from "react";
import Button from "@mui/material/Button";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab'
import { DataGrid } from "@mui/x-data-grid";
import classNames from "classnames";
import { connect } from "react-redux";
import Menu from '@mui/material/Menu';
import { bindActionCreators } from "redux";
import { fetchUserTableData, filterUserTableData, fetchAllProviders } from "../../../actions/index";
import PatientAdd from "./patientTable/patientAdd";
import OutlinedFlagOutlinedIcon from "@mui/icons-material/OutlinedFlagOutlined";
import SouthOutlinedIcon from "@mui/icons-material/SouthOutlined";
import NorthOutlinedIcon from "@mui/icons-material/NorthOutlined";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Link from "@material-ui/core/Link";
import AutoSearchBar from "./AutoSearchBar";
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterManager from './FilterManager'
import * as routes from "../../../constants/routes";

class PatientView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      perPage: 10,
      totalItems: 0,
      sortColumn: "lastName",
      sortOrder: "asc",
      isTrue: true,
      totalPages: 0,
      AddProjectModal: false,
      showFilter: false,
      anchorEl: false,
      complianceFilter: "all",
      Providers: [],
      filterData: {
        incompiance: false,
        atrisk: false,
        outofcompiance: false,
        providers: ['all']
      },
      filterCount: 0,
      providerName: ['All providers'],
      searchString: '',
      patientId: "",
    };
  }
  componentDidMount() {
    let currentUser = this.props.currentUser
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    if (currentUser == '' || Object.keys(currentUser).length == 0) {
      window.location.href = '/';
      return false;
    }
    else {
      let currentUserRole = currentUser.role;
      if (currentUserRole.toLowerCase() != 'provider') {
        // console.log('PATIENT VIEW USER==>',currentUserRole)
        this.props.history.push(routes.USER);
        return false;
      }
    }
    this.props.fetchUserTableData(
      "information",
      this.state.page,
      this.state.perPage,
      this.state.sortColumn,
      this.state.sortOrder,
      this.state.isTrue
      );
      this.handleChangePage = this.handleChangePage.bind(this);
      this.getProviders();
    }

  componentDidUpdate(prevProps) {
    if (this.props.tableData && prevProps.tableData !== this.props.tableData) {
      if (this.props.tableData.page) {
        //this.fetchPatients();
        this.setState({
          ...this.state,
          totalItems: this.props.tableData.page.totalItems,
          page: this.props.tableData.page.currentPage,
          perPage: this.props.tableData.page.pageSize,
          totalPages: this.props.tableData.page.totalPages,
        });
      }
    }

  }
  handleChangePage(page) {
    this.setState({ page: page });
    setTimeout(() => {
      this.getPatientList();
      window.scrollTo(0, 0)
    }, 100);
  }
  getPatientList() {
    let page = this.state.page;
    let perPage = this.state.perPage;
    if (this.state.filterCount > 0) {
      this.props.filterUserTableData(
        "information",
        page,
        perPage,
        this.state.sortColumn,
        this.state.sortOrder,
        this.state.isTrue,
        '',
        this.state.complianceFilter,
        this.state.providersFilter
      );
    } else {
      this.props.fetchUserTableData(
        "information",
        page,
        perPage,
        this.state.sortColumn,
        this.state.sortOrder,
        this.state.isTrue,
        '',
        '',
        ''
      );
    }
  }
  handleSortModelChange(sortModel) {
    //console.log("sortModel::::",sortModel);
    // Here you save the data you need from the sort model
    //setQueryOptions({ sortModel: [...sortModel] });

    let page = 1;
    let perPage = this.state.perPage;
    let sortColumn = sortModel[0].field;
    let sortOrder = sortModel[0].sort;
    this.setState({
      sortColumn: sortColumn,
      sortOrder: sortOrder,
      page: 1,
    });
    if (this.state.filterCount > 0) {
      this.props.filterUserTableData(
        "information",
        page,
        perPage,
        sortColumn,
        sortOrder,
        this.state.isTrue,
        '',
        this.state.complianceFilter,
        this.state.providersFilter
      );
    } else {
      this.props.fetchUserTableData(
        "information",
        page,
        perPage,
        sortColumn,
        sortOrder,
        this.state.isTrue,
        this.state.patientId
      );
    }
  }
  handleOpen = () => this.setState({ AddPatientModal: true });
  handleClose = () => this.setState({ AddPatientModal: false });
  myPatient(val) {
    setTimeout(() => {
      this.setState({ isTrue: val, page: 1 });
      this.props.fetchUserTableData(
        "information",
        this.state.page,
        this.state.perPage,
        this.state.sortColumn,
        this.state.sortOrder,
        this.state.isTrue
      );
      this.setState({ isTrue: val });
      this.props.fetchUserTableData(
        "information",
        this.state.page,
        this.state.perPage,
        this.state.sortColumn,
        this.state.sortOrder,
        this.state.isTrue
      );
      this.clearFilter();

    }, 200);

  }
  filterHandler = (event) => {
    let showFilter = this.state.showFilter;
    this.setState({ showFilter: !showFilter });
    this.setState({ anchorEl: event.currentTarget });
  }
  handleFilterClose = () => {
    this.setState({ anchorEl: null });
  }
  handleFilterSubmit = (filter, filterCount, providerName, closeFilter = true) => {
    this.setState({ isTrue: false });
    let complianceFilter = "";

    this.setState({ filterData: filter, page: 1 });
    if (filter.incompiance) {
      complianceFilter += "In Compliance,"
    }
    if (filter.atrisk) {
      complianceFilter += "At Risk,"
    }
    if (filter.outofcompiance) {
      complianceFilter += "Out of Compliance"
    }
    if (complianceFilter == '') {
      complianceFilter = "all";
    }
    complianceFilter = complianceFilter.replace(/,\s*$/, "");
    let providersFilter = filter.providers;
    console.log("providerName:::", filter.providers);
    if (providerName != '') {
      //providerName = providerName.split("|")
    }
    this.setState({ complianceFilter: complianceFilter, providersFilter: providersFilter, filterCount: filterCount, providerName: providerName });
    if (filterCount > 0) {

      setTimeout(() => {
        this.props.filterUserTableData(
          "information",
          this.state.page,
          this.state.perPage,
          this.state.sortColumn,
          this.state.sortOrder,
          this.state.isTrue,
          '',
          complianceFilter,
          providersFilter
        );
      }, 200);

    } else {
      setTimeout(() => {
        this.getPatientList();
      }, 200);
    }
    if (closeFilter === true) {
      this.handleFilterClose();
    }
  }
  getProviders = async () => {
    let providersData = await this.props.fetchAllProviders();
    //let providers = [{id:"all",name:"All Providers",checked:true}];
    //providers = [...providers,...providersData.data];
    let providers = providersData.data;
    this.setState({ Providers: providers });
  };
  clearFilter() {
    console.log("in clear filter");
    this.setState({
      filterData: {
        incompiance: false,
        atrisk: false,
        outofcompiance: false,
        providers: ['all'],
      },
      filterCount: 0,
      providerName: ['All providers']
    })
  }
  rowClickHandle(params) {
    let patientId = params.row.lastName.userId;
    console.log("params::::", patientId);
    this.props.history.push(`patientdetail/${patientId}`);
  }
  myPatientHandler() {
    this.setState({ isTrue: !this.state.isTrue });
    setTimeout(() => {
      this.myPatient(this.state.isTrue);
    }, 300)

  }
  setPatientKitId = (patientId) => {
    this.setState({ patientId: patientId });

  }
  render() {
    const NoResultsOverlay = () => {
      return <div className="no-result">0 results found</div>;
    };
    const CustomPagination = () => {
      return (
        <Pagination
          count={this.state.totalPages}
          page={this.state.page}
          variant="outlined"
          onChange={(event, value) => this.handleChangePage(value)}
        />
      );
    };
    let rows = [];
    const columns = [
      // {field: 'id',headerName: 'Id'},
      {
        field: "lastName",
        headerName: "Patient Name",
        flex: 1,
        sortingOrder: ["desc", "asc"],
        renderCell: (params) => (
          // <Link href={`patientdetail/${params.value.userId}`}>
          //   {params.value.fullName}
          // </Link>
          // <div onClick={() => this.props.history.push(`patientdetail/${params.value.userId}`)} className="font-figtree font-500 font-16 pointer text-primary-theme">{params.value.fullName}</div>
          params.value.fullName

        ),
      },
      {
        field: "compliance",
        headerName: "Compliance",
        flex: 1,
        sortable: false,
        renderCell: (cellValues) => {
          const className = classNames({
            "-ms-5 yellow-color": cellValues.value === "At Risk",
            "-ms-5 green-color": cellValues.value === "In Compliance",
            "-ms-5 red-color": cellValues.value === "Out of Compliance",
          });
          return (
            <>
              <OutlinedFlagOutlinedIcon className={className} />
              {cellValues.value}
            </>
          );
        },
      },
      {
        field: "mrn",
        headerName: "MRN",
        sortable: false,
        flex: 1,
      },
      {
        field: "phoneNumber",
        headerName: "Phone Number",
        flex: 1,
        sortable: false
      },
      {
        field: "ptName",
        headerName: "Provider Name",
        flex: 1,
        sortingOrder: ["desc", "asc"],
      },
    ];

    if (this.props.tableData != null) {
      let { rowList } = this.props.tableData;
      if (this.props.tableData.page !== undefined) {
        //console.log("this.props.tableData.page::::", this.props.tableData.page);
      }
      if (rowList && rowList !== null) {
        rowList.forEach((row, index) => {
          rows.push({
            id: index,
            lastName: row.user,
            compliance: row.compliance,
            //status: row.status,
            mrn: row.mrn,
            phoneNumber: row.phoneNumber,
            //program: row.program,
            ptName: row.ptName,
          });
        });
      }
    }
    const { history } = this.props;
    // console.log('CurrentUser===>',this.props.currentUser)
    return (
      <div className="p-3">

        {Object.keys(this.props.currentUser).length!==0 && this.props.currentUser.role.toLowerCase() !=='tvh super admin' &&
        <PatientAdd
          mode="Add"
          Providers={[]}
          AddPatientModal={this.state.AddPatientModal}
          handleClose={this.handleClose}
          page={this.state.page}
          perPage={this.state.perPage}
          sortColumn={this.state.sortColumn}
          sortOrder={this.state.sortOrder}
          isTrue={this.state.isTrue}
          history={history}
          patientId={this.state.patientId}
          complianceFilter={this.state.complianceFilter}
          providersFilter={this.state.providersFilter}
        />}
        <Grid container className="MuiToolbar-gutters">
          <Grid item xs={12}>
            <h2 className="pd-top-6 push-mt-30">
              <strong>Dashboard</strong>
            </h2>
            <AutoSearchBar
              tableData={this.props.tableData}
              onlyMe={this.state.isTrue}
              filterCount={this.state.filterCount}
              sortColumn={this.state.sortColumn}
              sortOrder={this.state.sortOrder}
              clearFilter={() => this.clearFilter()}
              setPatientId={(kitId) => this.setPatientKitId(kitId)}
            />
          </Grid>
          <div className="d-flex mb-3 w-100 align-items-center mt-3">
            <Button
              className="btn-secondary-outline pointer py-2 p1-1 pr-2 border-1 rounded-14 font-figtree font-16 font-600 text-capitalize"
              variant="contained"
              onClick={(e) => this.filterHandler(e)}
              aria-controls={'basic-menu'}
              aria-haspopup="true"
              aria-expanded={'true'}>
              <FilterListIcon></FilterListIcon>
              Filters
              <span className="bg-dark px-2 py-0 ml-2 rounded-20 text-white line-height-24">{this.state.filterCount > 0 ? this.state.filterCount : ''}</span>
            </Button>


            <div className="ml-5 p-2 bg-white border rounded-14 shadow ">
              <button
                className={"mr-1 pointer py-2 px-4 border-0 rounded-6 font-figtree font-400 font-16 " + (this.state.isTrue ? 'bg-secondary-theme text-white' : ' bg-transparent')}
                variant="contained" onClick={() => this.myPatientHandler()}>My patients</button>
              <button
                className={"ml-1 pointer py-2 px-4 border-0 rounded-6 font-figtree font-400 font-16  " + (!this.state.isTrue ? 'bg-secondary-theme text-white' : ' bg-transparent')}
                variant="contained" onClick={() => this.myPatientHandler()}>All patients</button>
            </div>
            <button
              className="ml-auto bg-primary-theme pointer py-2 px-4 border-0 rounded-14 text-white font-figtree font-700 font-18"
              variant="contained"
              onClick={() => this.handleOpen()}
            >
              Add Patient
            </button>
          </div>
          <Menu
            id="basic-menu"
            className="filter-menu"
            anchorEl={this.state.anchorEl}
            open={this.state.anchorEl}
            onClose={this.handleFilterClose.bind()}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <FilterManager handleFilterSubmit={(filter, filterCount, providerName, closeFilter) => this.handleFilterSubmit(filter, filterCount, providerName, closeFilter)} handleFilterClose={this.handleFilterClose.bind()} showFilter={this.state.showFilter} Providers={this.state.Providers} filterData={this.state.filterData} filterCount={this.state.filterCount} providerName={this.state.providerName} />
          </Menu>
          <Grid item xs={12}>
            <Box sx={{ height: "780px", width: "100%" }} className="datatable">
              <DataGrid
                onRowClick={(row) => this.rowClickHandle(row)}
                sx={{
                  '.MuiDataGrid-iconButtonContainer': {
                    visibility: 'visible',
                  },
                  '.MuiDataGrid-sortIcon': {
                    opacity: 'inherit !important',
                  },
                }}
                rows={rows}
                rowCount={this.state.totalItems}
                rowsPerPageOptions={this.state.page.perPage}
                pagination
                page={this.state.page}
                pageSize={5}
                paginationMode="server"
                columns={columns}
                disableColumnMenu="true"

                components={{
                  NoRowsOverlay: NoResultsOverlay,
                  Pagination: CustomPagination,
                }}
                initialState={{
                  sorting: {
                    sortModel: [
                      {
                        field: this.state.sortColumn,
                        sort: this.state.sortOrder,
                      },
                    ],
                  },
                }}
                sortingMode="server"
                onSortModelChange={(newSortModel) =>
                  this.handleSortModelChange(newSortModel)
                }
                disableRowSelectionOnClick={true}
              />
            </Box>
          </Grid>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    tableData: state.userTableData,
    currentUser: state.security.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchUserTableData,
      fetchAllProviders,
      filterUserTableData,
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(PatientView);
